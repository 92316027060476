<template>
  <img :src="require(`@/assets/brands-logo/${name}-logo.png`)" alt="">
</template>

<script>
export default {
  name: 'BrandLogo',
  props: ['name'],
}
</script>
