<template>
  <div class="page-modal">
    <div class="page-modal-overlay" @click="close"></div>

    <transition name="slide-fade">
      <div class="page-modal-content" v-if="isShow">
        <div class="overflow">
          <slot name="content"></slot>
        </div>

        <CloseButton
          class="page-modal-close"
          @onClick="close()"
        />
      </div>
    </transition>

  </div>
</template>

<script>
import CloseButton from '@/components/ui/forms/buttons/CloseButton'

export default {
  name: 'PageModalWrapper',
  components: {
    CloseButton,
  },
  props: ['model'],
  model: {
    prop: 'model',
    event: 'input',
  },
  data () {
    return {
      isShow: this.model,
    }
  },
  methods: {
    close () {
      debugger
      this.isShow = false
      this.onInputHandler()
    },
    onInputHandler () {
      this.$emit('input', this.isShow)
    },
  },
  watch: {
    model (v) {
      this.isShow = v
      this.$store.commit('setScrollEnable', false)
    },
  },
  beforeDestroy () {
    this.$store.commit('setScrollEnable', true)
  },
}

</script>

<style scoped lang="scss">
$gutter: 20px;

.page-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  @include flex(row, flex-end);
  z-index: 10;

  &-content {
    z-index: 1;
    background: #fff;
    width: 70%;
    position: relative;

    @media (max-width: 1420px) {
      width: 80%;
    }

    .overflow {
      overflow: auto;
      height: 100%;
      padding: $gutter;
      box-sizing: border-box;
    }
  }

  &-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  &-close {
    position: absolute;
    top: $gutter;
    left: -$gutter * 3;
    height: 40px;
    width: 40px;
    background: $color-line;
    border-radius: 100px;
    transition: .4s ease all;
    z-index: 1;

    :deep(line) {
      stroke: #000;
    }

    &:hover {
      background: rgba(229, 229, 229, 0.74);
    }
  }
}

.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(80px);
  opacity: 0;
}
</style>
